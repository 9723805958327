import React, { Dispatch, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../../../reducers";
import * as actions from "../../../../../actions";
import {
  ButtonColors,
  ButtonTypes,
  Button,
  Input,
  ButtonStyles,
} from "icr-shared-components";
import { FormattedMessage } from "react-intl";
import { Controller, useForm } from "react-hook-form";
import {
  checkDiffInDays,
  dateFilter,
  parseDate,
} from "../../../../../utils/react";
import { ValidationMessage } from "../../../../../@types/forms/agp/formValidation";
import { validateInputMessage } from "../../../../../utils/validationTools";
import { EValidationTestType } from "../../../../../utils/validationConfigs";
import { ProcessingStates } from "../../../../../models";

type Props = {
  formConfig: any;
  initialFormToCompare: any;
  getValues: any;
  setValue: any;
  setFormConfig: any;
  setStep: any;
  closeModal: any;
  submitAction: any;
  employers: any;
  entities: any;
  initialData: any;
  showValidations: boolean;
  type: string;
};

function DiseaseStep1(props: Props) {
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [requiredFields, setRequiredFields] = useState({});

  useEffect(() => {
    if (JSON.stringify(requiredFields) !== JSON.stringify({})) {
      setIsDisabledButton(
        !Object.keys(requiredFields).every((key) => {
          return requiredFields[key];
        })
      );
    } else {
      setIsDisabledButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredFields]);

  const {
    setValue,
    getValues,
    setError,
    control,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      employee: {
        unemployabilityDate:
          props.initialData.employee.unemployabilityDate || "",
      },
      requiredFields: {},
    },
  });

  const haveCustomValidations = (formInput: string): EValidationTestType[] => {
    const validations = [];
    !!props.employers[props.initialData.employerId]?.attributes
      .changesAllowedFrom && validations.push(EValidationTestType.dateBetween);
    !!props.formConfig.formCustomization.components[formInput]?.date
      ?.dayFilter &&
      validations.push(
        dateFilter(
          props.formConfig.formCustomization.components[formInput]?.date
            ?.dayFilter
        )
      );
    return validations;
  };

  const requiredFieldsDiseaseStep1 = useCallback(() => {
    const requiredFieldsDiseaseStep1 = {};
    Object.keys({
      unemployabilityDate:
        props.getValues("initialData.employee.unemployabilityDate") || "",
    }).map(
      (key) =>
        props.formConfig.formCustomization.components[key]?.required &&
        (requiredFieldsDiseaseStep1[key] = !!getValues("employee")[key])
    );
    setValue("requiredFields", requiredFieldsDiseaseStep1);
  }, [props.formConfig.formCustomization, getValues, setValue]);

  useEffect(() => {
    if (Object.keys(props.formConfig.formCustomization.components).length > 0) {
      requiredFieldsDiseaseStep1();
    }
  }, [props.formConfig.formCustomization, requiredFieldsDiseaseStep1]);

  useEffect(() => {
    setIsDisabledButton(
      !Object.keys(getValues("requiredFields")).every(
        (key) => getValues("requiredFields")[key]
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("requiredFields")]);

  return (
    <form className="form">
      <fieldset
        disabled={
          props.initialData.employee.processingState ===
            ProcessingStates.Committed ||
          props.initialData.employee.processingState ===
            ProcessingStates.Exported
        }
      >
        <div className="row">
          <div className="col-12 col-sm-6">
            <Controller
              control={control}
              name="employee.unemployabilityDate"
              render={({ field: { value } }) => (
                <Input
                  isFocused
                  type="date"
                  maxLength={10}
                  hidden={
                    props.formConfig.formCustomization.components[
                      "unemployabilityDate"
                    ]?.hidden
                  }
                  inputWrapperClass="text-input"
                  id={"quittingDate"}
                  name={"quittingDate"}
                  placeholder=""
                  value={value && value.length >= 10 ? parseDate(value) : value}
                  onChange={(name, valueInput) => {
                    setValue("employee.unemployabilityDate", valueInput);
                    const validation: ValidationMessage = validateInputMessage(
                      props.formConfig.formCustomization,
                      getValues("employee"),
                      "unemployabilityDate",
                      null,
                      requiredFields
                    );
                    setError("employee.unemployabilityDate", validation);
                    validation?.valid && setRequiredFields(validation?.valid);
                  }}
                  onBlur={() => {
                    const validation: ValidationMessage = validateInputMessage(
                      props.formConfig.formCustomization,
                      getValues("employee"),
                      "unemployabilityDate",
                      haveCustomValidations,
                      requiredFields
                    );
                    setError("employee.unemployabilityDate", validation);
                    validation?.valid && setRequiredFields(validation?.valid);
                  }}
                  validationMessageConfig={{
                    visible: props.showValidations,
                    config: {
                      message: errors?.employee?.unemployabilityDate?.message,
                      type: errors?.employee?.unemployabilityDate?.type as any,
                    },
                  }}
                >
                  <FormattedMessage
                    id="app.unemployabilitydate"
                    defaultMessage="Arbeitsunfähig seit"
                  />
                  {props.formConfig.formCustomization.components["quittingDate"]
                    ?.required
                    ? " *"
                    : ""}
                </Input>
              )}
            />
          </div>

          <div className="col-12 col-sm-6"></div>
          <div className="col-12 col-sm-6"></div>
          <div className="col-12 col-md-6">
            <div className="modal-buttons">
              <Button
                onClick={() => props.setStep("start")}
                type={ButtonTypes.Text}
                color={ButtonColors.Action}
              >
                <FormattedMessage id="app.cancel" defaultMessage="Abbrechen" />
              </Button>
              <Button
                submit={true}
                type={ButtonTypes.Standard}
                color={ButtonColors.Action}
                style={ButtonStyles.Primary}
                isDisabled={
                  isDisabledButton ||
                  props.initialFormToCompare.unemployabilityDate ===
                    getValues("employee.unemployabilityDate")
                }
                onClick={(e) => {
                  props.setValue("insurantDataPatch.employee", {
                    ...props.getValues("insurantDataPatch.employee"),
                    unemployabilityDate: getValues(
                      "employee.unemployabilityDate"
                    ),
                  });
                  if (
                    checkDiffInDays(
                      props.getValues(
                        "insurantDataPatch.employee.quittingDate"
                      ),
                      getValues("employee.unemployabilityDate")
                    ) > 28
                  ) {
                    props.setStep("diseasePlus28");
                  } else {
                    props.submitAction(e);
                  }
                }}
              >
                <FormattedMessage id="app.submit" defaultMessage="" />
              </Button>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  );
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    employers: state.app.employers,
    entities: state.app.entities,
    isLoading: state.app.loading,
    insurantsObj: state.insurants.insurantsObj,
    showValidations: state.app.showValidations,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DiseaseStep1);
