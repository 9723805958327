import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { RootState } from "../../../reducers/index";
import { Dispatch, bindActionCreators } from "redux";
import * as actions from "../../../actions/";
import {
  buildDateFromString,
  calculateAgeFromDates,
  isObjEmpty,
  parseDate,
  prepareGendersEntitiesDropdownOptions,
  prepareInsurancePlanObject,
  prepareInsurancePlansDropdownOptions,
} from "../../../utils/react";
import { EValidationTestType } from "../../../utils/validationConfigs";
import { ProcessingStates } from "../../../models";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
  Input,
} from "icr-shared-components";
import formName from "../../../constants/forms/formName.json";
import { fetchFormCustomization, filterOnlyLetters } from "../../../utils/form";
import "./FormInsurantInfo.scss";
import { FormattedMessage, injectIntl } from "react-intl";
import Tooltip from "rc-tooltip";
import { ReactComponent as IconHelp } from "../../../assets/icons/help.svg";
import { useForm, Controller } from "react-hook-form";
import {
  buildValidationBasedOnEmployerDates,
  formHasChanged,
  validateInputMessage,
} from "../../../utils/validationTools";
import {
  ValidationMessage,
  ValidationFormProps,
} from "../../../@types/forms/agp/formValidation";
import Spinner from "../../Spinner";

interface Props {
  anstellungsanderung?: boolean;
  checkFormValidity: any;
  closeModal: any;
  deleteMutation?: any;
  entities?: any;
  exitReasonOptions?: any;
  initialData: any;
  isLoading: boolean;
  personalienanderung?: boolean;
  submitAction: any;
  intl: any;
  employers: any;
  backendValidation: any;
  removeBackendValidationMessage: any;
  fetchEmployersInsurancePlans: (employerId: string, isSelect?: boolean) => any;
}

const helperFieldsCategory = {
  name: "person",
  firstname: "person",
  sex: "person",
  socialSecurityNumber: "person",
  birthDate: "person",
  entryDate: "employee",
  insurancePlan: "employee",
  costCenter: "employee",
  extEmployeeId: "employee",
};

const FormVersichertenInfos = (props: Props) => {
  const showValidations = true;
  const globalSettings = JSON.parse(localStorage.getItem("global-settings"));
  const [formConfig, setFormConfig] = useState<ValidationFormProps>({
    formCustomization: {
      components: {
        insurancePlan: {
          hidden: globalSettings.attributes.tenantCode === "PKSH" && true,
        },
      },
      messages: {},
    },
    requiredFields: {},
  });
  const [initialFormToCompare, setInitialFormToCompare] = useState();
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [insurancePlanObject, setInsurancePlanObject] = useState<any>();
  const [insurancePlanOptions, setInsurancePlanOptions] = useState<any>();
  const [isLoadingInsurancePlan, setIsLoadingInsurancePlan] = useState(false);

  const currentTenant = globalSettings?.attributes?.tenantCode;

  const {
    setValue,
    getValues,
    setError,
    control,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      insurantDataPatch: {
        ...props.initialData,
        person: {
          ...props.initialData.person,
          birthDate: props.initialData.person.birthDate
            ? parseDate(props.initialData.person.birthDate)
            : "",
        },
        employee: {
          ...props.initialData.employee,
          birthDate: props.initialData.person.birthDate
            ? parseDate(props.initialData.person.birthDate)
            : "",
          entryDate: props.initialData.employee.entryDate
            ? parseDate(props.initialData.employee.entryDate)
            : "",
        },
      },
    },
  });

  const sexOptions = useSelector((state: RootState) => {
    return prepareGendersEntitiesDropdownOptions(
      !isObjEmpty(state.app.entities.SexCodes)
        ? state.app.entities.SexCodes.values
        : {}
    );
  });

  const getFetchFormCustomization = async () => {
    const response = await fetchFormCustomization(
      globalSettings.attributes.tenantCode,
      formName["formName.formInsurantInfo"]
    );
    if (response.data) {
      let formComponentsCustomization = {};
      const employerConfiguration =
        props.employers[props.initialData.employee.employerId];

      response["data"].attributes?.components.map(
        (item) =>
          (formComponentsCustomization[item.id] = item.componentAttributes)
      );
      let requiredFields = {};
      Object.keys(formComponentsCustomization).map(
        (key) =>
          formComponentsCustomization[key].required &&
          (requiredFields[key] = !!getValues(
            `insurantDataPatch.${helperFieldsCategory[key]}`
          )[key])
      );

      // Build custom frontend validation
      formComponentsCustomization = buildValidationBasedOnEmployerDates(
        employerConfiguration,
        formComponentsCustomization,
        "entryDate"
      );

      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: formComponentsCustomization,
          messages: {},
        },
        requiredFields,
      });
    } else {
      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: {
            firstname: {
              required: true,
            },
            name: {
              required: true,
            },
            birthDate: {
              required: true,
              date: {
                dayFilter: 0,
              },
            },
            socialSecurityNumber: {
              required: true,
            },
            entryDate: {
              required: true,
              date: {
                dayFilter: 1,
              },
            },
            sex: {
              required: false,
            },
            extEmployeeId: {
              required: false,
            },
            costCenter: {
              required: false,
            },
            insurancePlan: {
              required: false,
            },
          },
          messages: {},
        },
        requiredFields: {
          birthDate: !!getValues("insurantDataPatch.person.birthDate"),
          socialSecurityNumber: !!getValues(
            "insurantDataPatch.person.socialSecurityNumber"
          ),
          entryDate: !!getValues("insurantDataPatch.employee.entryDate"),
        },
      });
    }
    // Object assign to remove reference
    const actualValues = Object.assign(
      {},
      {
        ...getValues("insurantDataPatch"),
        person: {
          ...props.initialData.person,
          birthDate: props.initialData.person.birthDate
            ? parseDate(props.initialData.person.birthDate)
            : "",
        },
        employee: {
          ...props.initialData.employee,
          entryDate: props.initialData.employee.entryDate
            ? parseDate(props.initialData.employee.entryDate)
            : "",
          birthDate: props.initialData.person.birthDate
            ? parseDate(props.initialData.person.birthDate)
            : "",
        },
      }
    );
    // Compare on edit
    setInitialFormToCompare(actualValues);
  };

  useEffect(() => {
    getFetchFormCustomization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const employerId = getValues("insurantDataPatch.employee.employerId");

    if (employerId) {
      adjustInsurancePlan(employerId);
    }
  }, []);

  const adjustInsurancePlan = (employerId) => {
    let defaultInsurancePlanCode = getValues(
      "insurantDataPatch.employee.insurancePlan"
    );
    setIsLoadingInsurancePlan(true);

    const insurancePlansByProps = () => {
      if (
        !defaultInsurancePlanCode &&
        props.employers[employerId] &&
        props.employers[employerId]["attributes"]["insurancePlans"] &&
        props.employers[employerId]["attributes"]["defaultInsurancePlanId"]
      ) {
        let defaultInsurancePlanId =
          props.employers[employerId]["attributes"]["defaultInsurancePlanId"];
        defaultInsurancePlanCode = props.employers[employerId]["attributes"][
          "insurancePlans"
        ].find((plan) => plan["id"] == defaultInsurancePlanId)["code"];
      }

      setValue(
        "insurantDataPatch.employee.insurancePlan",
        defaultInsurancePlanCode
      );

      const internalInsurancePlanObject = prepareInsurancePlanObject(
        props.employers?.[employerId]?.attributes?.insurancePlans
      );

      setInsurancePlanObject(internalInsurancePlanObject);
      setInsurancePlanOptions(
        prepareInsurancePlansDropdownOptions(internalInsurancePlanObject)
      );
    };

    insurancePlansByProps();
    setIsLoadingInsurancePlan(false);
  };

  const mustDisableInsurancePlanInput = () => {
    const birthDate = getValues().insurantDataPatch.person.birthDate.includes(
      "-"
    )
      ? new Date(getValues().insurantDataPatch.person.birthDate)
      : buildDateFromString(getValues().insurantDataPatch.person.birthDate);

    const age = calculateAgeFromDates(new Date(), birthDate);

    return currentTenant?.toLowerCase().includes("propublic") && age <= 44;
  };

  useEffect(() => {
    setIsDisabledButton(
      !Object.keys(formConfig.requiredFields).every(
        (key) => formConfig.requiredFields[key]
      )
    );
  }, [formConfig]);

  const handleSexOptionRadioChange = (name, value) => {
    setValue("insurantDataPatch.person.sex", value);
    const validation: ValidationMessage = validateInputMessage(
      formConfig.formCustomization,
      getValues("insurantDataPatch.person"),
      "sex",
      null,
      formConfig.requiredFields
    );
    setError("insurantDataPatch.person.sex", validation);
    validation?.valid &&
      setFormConfig({ ...formConfig, requiredFields: validation?.valid });
  };

  const prepareFormInputsSexRadioOptions = (options) => {
    return options.map((option) => ({
      ...option,
      name: "sex",
      type: "radio",
      label: option.label,
      value: option.value,
      validationMessageConfig: null,
      validationTests: [EValidationTestType.hasValue],
      onChange: handleSexOptionRadioChange,
    }));
  };

  const haveCustomValidationsEntryDate = (): EValidationTestType[] => {
    const arr = [EValidationTestType.dateIsNotBeforeBirthdate];
    !!props.employers[props.initialData.employee.employerId]?.attributes
      .changesAllowedFrom && arr.push(EValidationTestType.dateBetween);
    return globalSettings.attributes.tenantCode === "PKSH"
      ? arr.concat(EValidationTestType.isFirstDayOfTheMonth)
      : arr;
  };

  const haveCustomValidationsAHV = (): EValidationTestType[] => {
    return [EValidationTestType.isSsn];
  };

  const buildEntryDateValidation = (formConfigRequired) => {
    const validation: ValidationMessage = validateInputMessage(
      formConfig.formCustomization,
      getValues("insurantDataPatch.employee"),
      "entryDate",
      haveCustomValidationsEntryDate,
      formConfigRequired
    );
    setError("insurantDataPatch.employee.entryDate", validation);
    validation?.valid &&
      setFormConfig({
        ...formConfig,
        requiredFields: validation?.valid,
      });
  };

  const sexOptionsFormInputs = prepareFormInputsSexRadioOptions(sexOptions);
  const isSsnTooltipVisible =
    props.intl.formatMessage({
      id: "app.forms.versicherteninfos.socialSecurityNumber.tooltip",
    }) !== "app.forms.versicherteninfos.socialSecurityNumber.tooltip";
  const isPlanTooltipVisible =
    currentTenant?.toLowerCase().includes("pksh") ||
    currentTenant?.toLowerCase().includes("propublic");

  return (
    <>
      {props.isLoading ? (
        <Spinner relative />
      ) : (
        <form
          className="form"
          onSubmit={(e) => {
            console.log("onsubmit", e);
            e.preventDefault();
          }}
        >
          <fieldset
            disabled={
              props.initialData.employee.processingState ===
                ProcessingStates.Committed ||
              props.initialData.employee.processingState ===
                ProcessingStates.Exported
            }
          >
            <div className="row">
              {/* Name */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="insurantDataPatch.person.name"
                  render={({ field: { value } }) => (
                    <Input
                      isFocused
                      type="text"
                      inputWrapperClass="text-input"
                      hidden={
                        formConfig.formCustomization.components["name"]?.hidden
                      }
                      id={"name"}
                      name={"name"}
                      placeholder={props.intl.formatMessage({
                        id: "app.entername",
                        defaultMessage: "Name eingeben",
                      })}
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue(
                          "insurantDataPatch.person.name",
                          filterOnlyLetters(valueInput)
                        );

                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("insurantDataPatch.person"),
                            "name",
                            null,
                            formConfig.requiredFields
                          );
                        setError("insurantDataPatch.person.name", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("insurantDataPatch.person"),
                            "name",
                            null,
                            formConfig.requiredFields
                          );
                        setError("insurantDataPatch.person.name", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message:
                            errors?.insurantDataPatch?.person?.name?.message,
                          type: errors?.insurantDataPatch?.person?.name?.type,
                        },
                      }}
                    >
                      <FormattedMessage id="app.name" defaultMessage="Name" />
                      {formConfig.formCustomization.components["name"]?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              {/* Vorname */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="insurantDataPatch.person.firstname"
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      id={"firstname"}
                      inputWrapperClass="text-input"
                      hidden={
                        formConfig.formCustomization.components["firstname"]
                          ?.hidden
                      }
                      name={"firstname"}
                      placeholder={props.intl.formatMessage({
                        id: "app.enterfirstname",
                        defaultMessage: "Vorname eingeben",
                      })}
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue(
                          "insurantDataPatch.person.firstname",
                          filterOnlyLetters(valueInput)
                        );
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("insurantDataPatch.person"),
                            "firstname",
                            null,
                            formConfig.requiredFields
                          );
                        setError(
                          "insurantDataPatch.person.firstname",
                          validation
                        );
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("insurantDataPatch.person"),
                            "firstname",
                            null,
                            formConfig.requiredFields
                          );
                        setError(
                          "insurantDataPatch.person.firstname",
                          validation
                        );
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message:
                            errors?.insurantDataPatch?.person?.firstname
                              ?.message,
                          type: errors?.insurantDataPatch?.person?.firstname
                            ?.type,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.firstname"
                        defaultMessage="Vorname"
                      />
                      {formConfig.formCustomization.components["firstname"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              {/* Geburtsdatum */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="insurantDataPatch.person.birthDate"
                  render={({ field: { value } }) => (
                    <Input
                      type="date"
                      maxLength={10}
                      inputWrapperClass="text-input"
                      id={"birthDate"}
                      hidden={
                        formConfig.formCustomization.components["birthDate"]
                          ?.hidden
                      }
                      name={"birthDate"}
                      placeholder={props.intl.formatMessage({
                        id: "app.birthdate",
                        defaultMessage: "Geburtsdatum",
                      })}
                      value={
                        value && value.length >= 10 ? parseDate(value) : value
                      }
                      onChange={(name, valueInput) => {
                        setValue(
                          "insurantDataPatch.person.birthDate",
                          valueInput
                        );
                        setValue(
                          "insurantDataPatch.employee.birthDate",
                          valueInput
                        );

                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("insurantDataPatch.person"),
                            "birthDate",
                            () => [
                              EValidationTestType.dateIsNotInTheFuture,
                              EValidationTestType.civilStatusDateNotBeforeBirthDate,
                            ],
                            formConfig.requiredFields
                          );
                        setError(
                          "insurantDataPatch.person.birthDate",
                          validation
                        );
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });

                        buildEntryDateValidation(validation?.valid);
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("insurantDataPatch.person"),
                            "birthDate",
                            () => [EValidationTestType.dateIsNotInTheFuture],
                            formConfig.requiredFields
                          );
                        setError(
                          "insurantDataPatch.person.birthDate",
                          validation
                        );
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });

                        buildEntryDateValidation(validation?.valid);
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message:
                            errors?.insurantDataPatch?.person?.birthDate
                              ?.message,
                          type: errors?.insurantDataPatch?.person?.birthDate
                            ?.type,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.birthdate"
                        defaultMessage="Geburtsdatum"
                      />
                      {formConfig.formCustomization.components["birthDate"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              {/* Geslecht */}
              {/* {!props.anstellungsanderung && */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="insurantDataPatch.person.sex"
                  render={({ field: { value } }) => (
                    <Input
                      id={"sex"}
                      inputWrapperClass="radio-input"
                      hidden={
                        formConfig.formCustomization.components["sex"]?.hidden
                      }
                      name={"sex"}
                      radioOptions={sexOptionsFormInputs}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message:
                            errors?.insurantDataPatch?.person?.sex?.message,
                          type: errors?.insurantDataPatch?.person?.sex?.type,
                        },
                      }}
                      forcedValue={value.toString()}
                    >
                      <FormattedMessage
                        id="app.sex"
                        defaultMessage="Geschlecht"
                      />
                      {formConfig.formCustomization.components["sex"]?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              {/* AHV */}
              {/* !props.anstellungsanderung &&  */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="insurantDataPatch.person.socialSecurityNumber"
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      id={"socialSecurityNumber"}
                      hidden={
                        formConfig.formCustomization.components[
                          "socialSecurityNumber"
                        ]?.hidden
                      }
                      name={"socialSecurityNumber"}
                      placeholder={props.intl.formatMessage({
                        id: "app.forms.versicherteninfos.socialSecurityNumber.placeholder",
                        defaultMessage: "AHV-Nummer eingeben",
                      })}
                      inputWrapperClass="text-input"
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue(
                          "insurantDataPatch.person.socialSecurityNumber",
                          valueInput
                        );
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("insurantDataPatch.person"),
                            "socialSecurityNumber",
                            haveCustomValidationsAHV,
                            formConfig.requiredFields
                          );
                        setError(
                          "insurantDataPatch.person.socialSecurityNumber",
                          validation
                        );
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("insurantDataPatch.person"),
                            "socialSecurityNumber",
                            haveCustomValidationsAHV,
                            formConfig.requiredFields
                          );
                        setError(
                          "insurantDataPatch.person.socialSecurityNumber",
                          validation
                        );
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message:
                            errors?.insurantDataPatch?.person
                              ?.socialSecurityNumber?.message,
                          type: errors?.insurantDataPatch?.person
                            ?.socialSecurityNumber?.type,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.mutationcard.values.person.socialSecurityNumber"
                        defaultMessage="AHV-Nummer"
                      />
                      {formConfig.formCustomization.components[
                        "socialSecurityNumber"
                      ]?.required
                        ? " *"
                        : ""}
                      {isSsnTooltipVisible && (
                        <Tooltip
                          trigger={["click"]}
                          placement="top"
                          overlay={
                            <FormattedMessage
                              id="app.forms.versicherteninfos.socialSecurityNumber.tooltip"
                              defaultMessage="AHV-Nummer"
                            />
                          }
                          arrowContent={
                            <div className="rc-tooltip-arrow-inner" />
                          }
                        >
                          <div className="help-icon pointer">
                            <IconHelp />
                          </div>
                        </Tooltip>
                      )}
                    </Input>
                  )}
                />
              </div>

              {/* Personal Number */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="insurantDataPatch.employee.extEmployeeId"
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      id={"extEmployeeId"}
                      hidden={
                        formConfig.formCustomization.components["extEmployeeId"]
                          ?.hidden
                      }
                      name={"extEmployeeId"}
                      placeholder={props.intl.formatMessage({
                        id: "app.personalnumber",
                        defaultMessage: "Personal-Nummer",
                      })}
                      inputWrapperClass="text-input"
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue(
                          "insurantDataPatch.employee.extEmployeeId",
                          valueInput
                        );
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("insurantDataPatch.employee"),
                            "extEmployeeId",
                            () => [EValidationTestType.isNumber],
                            formConfig.requiredFields
                          );
                        setError(
                          "insurantDataPatch.employee.extEmployeeId",
                          validation
                        );
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("insurantDataPatch.employee"),
                            "extEmployeeId",
                            () => [EValidationTestType.isNumber],
                            formConfig.requiredFields
                          );
                        setError(
                          "insurantDataPatch.employee.extEmployeeId",
                          validation
                        );
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message:
                            errors?.insurantDataPatch?.employee?.extEmployeeId
                              ?.message,
                          type: errors?.insurantDataPatch?.employee
                            ?.extEmployeeId?.type,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.personalnumber"
                        defaultMessage="Personal-Nummer"
                      />
                      {formConfig.formCustomization.components["extEmployeeId"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              {/* Eintrittsdatum */}
              {/* !props.personalienanderung && */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="insurantDataPatch.employee.entryDate"
                  render={({ field: { value } }) => (
                    <Input
                      type="date"
                      id={"entryDate"}
                      hidden={
                        formConfig.formCustomization.components["entryDate"]
                          ?.hidden
                      }
                      name={"entryDate"}
                      placeholder={props.intl.formatMessage({
                        id: "app.enterinsuranceentrydate",
                        defaultMessage: "Versicherung Eintrittsdatum",
                      })}
                      inputWrapperClass="text-input"
                      maxLength={10}
                      value={
                        value && value.length >= 10 ? parseDate(value) : value
                      }
                      onChange={(name, valueInput) => {
                        setValue(
                          "insurantDataPatch.employee.entryDate",
                          valueInput
                        );
                        buildEntryDateValidation(formConfig.requiredFields);
                      }}
                      onBlur={() => {
                        buildEntryDateValidation(formConfig.requiredFields);
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message:
                            errors?.insurantDataPatch?.employee?.entryDate
                              ?.message,
                          type: errors?.insurantDataPatch?.employee?.entryDate
                            ?.type,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.insuranceentrydate"
                        defaultMessage="Versicherung Eintrittsdatum"
                      />
                      {formConfig.formCustomization.components["entryDate"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              {/* Koenstelle */}
              {/* !props.personalienanderung &&  */}
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="insurantDataPatch.employee.costCenter"
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      id={"costCenter"}
                      hidden={
                        formConfig.formCustomization.components["costCenter"]
                          ?.hidden
                      }
                      name={"costCenter"}
                      placeholder={props.intl.formatMessage({
                        id: "app.entercostcenter",
                        defaultMessage: "Kostenstelle eingeben",
                      })}
                      inputWrapperClass="text-input"
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue(
                          "insurantDataPatch.employee.costCenter",
                          valueInput
                        );
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("insurantDataPatch.employee"),
                            "costCenter",
                            null,
                            formConfig.requiredFields
                          );
                        setError(
                          "insurantDataPatch.employee.costCenter",
                          validation
                        );
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("insurantDataPatch.employee"),
                            "costCenter",
                            null,
                            formConfig.requiredFields
                          );
                        setError(
                          "insurantDataPatch.employee.costCenter",
                          validation
                        );
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message:
                            errors?.insurantDataPatch?.employee?.costCenter
                              ?.message,
                          type: errors?.insurantDataPatch?.employee?.costCenter
                            ?.type,
                        },
                      }}
                    >
                      <FormattedMessage
                        id={"app.costcenter"}
                        defaultMessage="Kostenstelle"
                      />
                      {formConfig.formCustomization.components["costCenter"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              {/* Versicherungsplan */}
              <div className="col-12 col-sm-6">
                {props.employers[
                  getValues().insurantDataPatch.employee.employerId
                ] &&
                  insurancePlanObject &&
                  !isLoadingInsurancePlan && (
                    <Controller
                      control={control}
                      name="insurantDataPatch.employee.insurancePlan"
                      render={({ field: { value } }) => (
                        <Input
                          type="text"
                          id={"insurancePlan"}
                          hidden={
                            formConfig.formCustomization.components[
                              "insurancePlan"
                            ]?.hidden
                          }
                          disabled={mustDisableInsurancePlanInput()}
                          name={"insurancePlan"}
                          inputWrapperClass="dropdown-input"
                          placeholder={props.intl.formatMessage({
                            id: "app.forms.versicherteninfos.insurancePlan.placeholder",
                            defaultMessage: "Versicherungsplan",
                          })}
                          label="Anrede"
                          value={
                            insurancePlanObject[value]
                              ? insurancePlanObject[value]["name"]
                              : value
                          }
                          selectOptions={insurancePlanOptions}
                          onSelected={(name, valueInput) => {
                            setValue(
                              "insurantDataPatch.employee.insurancePlan",
                              valueInput
                            );
                            const validation: ValidationMessage =
                              validateInputMessage(
                                formConfig.formCustomization,
                                getValues("insurantDataPatch.employee"),
                                "insurancePlan",
                                null,
                                formConfig.requiredFields
                              );
                            setError(
                              "insurantDataPatch.employee.insurancePlan",
                              validation
                            );
                            validation?.valid &&
                              setFormConfig({
                                ...formConfig,
                                requiredFields: validation?.valid,
                              });
                          }}
                          onBlur={() => {
                            const validation: ValidationMessage =
                              validateInputMessage(
                                formConfig.formCustomization,
                                getValues("insurantDataPatch.employee"),
                                "insurancePlan",
                                null,
                                formConfig.requiredFields
                              );
                            setError(
                              "insurantDataPatch.employee.insurancePlan",
                              validation
                            );
                            validation?.valid &&
                              setFormConfig({
                                ...formConfig,
                                requiredFields: validation?.valid,
                              });
                          }}
                          validationMessageConfig={{
                            visible: showValidations,
                            config: {
                              message:
                                errors?.insurantDataPatch?.employee
                                  ?.insurancePlan?.message,
                              type: errors?.insurantDataPatch?.employee
                                ?.insurancePlan?.type,
                            },
                          }}
                          sort
                        >
                          <FormattedMessage
                            id={
                              currentTenant?.toLowerCase().includes("propublic")
                                ? "app.forms.versicherteninfos.propublic.insurancePlan"
                                : "app.forms.versicherteninfos.insurancePlan"
                            }
                            defaultMessage="Insurance Plan"
                          />
                          {formConfig.formCustomization.components[
                            "insurancePlan"
                          ]?.required
                            ? " *"
                            : ""}
                          {isPlanTooltipVisible && (
                            <span className="z-index-high">
                              <Tooltip
                                trigger={["click"]}
                                placement="top"
                                overlay={
                                  currentTenant
                                    ?.toLowerCase()
                                    .includes("propublic") ? (
                                    <span>
                                      Ab dem 45. Lebensjahr besteht die Option
                                      zur Wahl der Überbrückungsrente. Für
                                      Versicherte unter 45 Jahren ist die
                                      Überbrückungsrente standardmässig ohne
                                      zusätzliche Beitragskosten aktiviert.
                                    </span>
                                  ) : (
                                    <span>
                                      Der Versicherungsplan darf nur beim
                                      Eintritt durch den Arbeitgeber geändert
                                      werden. Alle nachfolgenden Anpassungen
                                      müssen vom Versicherten im Portal
                                      vorgenommen werden.
                                    </span>
                                  )
                                }
                                arrowContent={
                                  <div className="rc-tooltip-arrow-inner"></div>
                                }
                              >
                                <div className="help-icon pointer">
                                  <IconHelp />
                                </div>
                              </Tooltip>
                            </span>
                          )}
                        </Input>
                      )}
                    />
                  )}
              </div>
              <div className="col-12 col-md-12">
                <div className="modal-buttons">
                  <Button
                    onClick={props.closeModal}
                    type={ButtonTypes.Text}
                    color={ButtonColors.Action}
                  >
                    <FormattedMessage
                      id="app.cancel"
                      defaultMessage="Abbrechen"
                    />
                  </Button>
                  <Button
                    type={ButtonTypes.Standard}
                    color={ButtonColors.Action}
                    style={ButtonStyles.Primary}
                    isDisabled={
                      props.isLoading ||
                      isDisabledButton ||
                      !formHasChanged(
                        initialFormToCompare,
                        getValues("insurantDataPatch")
                      )
                    }
                    onClick={(e) =>
                      props.submitAction(e, getValues("insurantDataPatch"))
                    }
                  >
                    <FormattedMessage
                      id="app.submit"
                      defaultMessage="Bestätigen"
                    />
                  </Button>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      )}
    </>
  );
};

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    entities: state.app.entities,
    isLoading: state.app.loading,
    employers: state.app.employers,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
    fetchEmployersInsurancePlans: bindActionCreators(
      actions.fetchEmployersInsurancePlans,
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(FormVersichertenInfos));
