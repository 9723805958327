import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { RootState } from "../../../reducers/index";
import { Dispatch } from "redux";
import * as actions from "../../../actions";
import {
  isObjEmpty,
  parseDate,
  prepareEntitiesDropdownOptions,
} from "../../../utils/react";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
  Input,
  ValidationMessageType,
} from "icr-shared-components";
import { CivilStatusCodes, ProcessingStates } from "../../../models";
import "./FormCivilStatus.scss";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  ValidationFormProps,
  ValidationMessage,
} from "../../../@types/forms/agp/formValidation";
import { Controller, useForm } from "react-hook-form";
import { fetchFormCustomization, filterOnlyLetters } from "../../../utils/form";
import {
  EValidationTestType,
  formHasChanged,
  validateInputMessage,
} from "../../../utils/validationTools";
import Spinner from "../../Spinner";

interface State {
  // insurantData: any;
  insurantDataPatch: any;
}

interface Props {
  checkFormValidity: any;
  closeModal: any;
  entities?: any;
  initialData: any;
  isLoading: boolean;
  submitAction: any;
  intl: any;
  insurantsObj: any;
  employers: any;
  location: any;
  backendValidation: any;
  removeBackendValidationMessage: any;
  showValidations: boolean;
  history: any;
}

let isMarriageDateRequired = null;
let isDivorceDateRequired = null;

const FormCivilStatus = ({
  intl,
  isLoading,
  submitAction,
  history,
  showValidations,
  entities,
  initialData,
}: Props) => {
  const [formConfig, setFormConfig] = useState<ValidationFormProps>({
    formCustomization: {
      components: {},
      messages: {},
    },
    requiredFields: {},
  });
  const {
    setValue,
    getValues,
    setError,
    control,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      person: {
        ...initialData.person,
        customizable2: initialData.person.customizable2
          ? parseDate(initialData.person.customizable2)
          : "",
        marriageDate: initialData.person.marriageDate
          ? parseDate(initialData.person.marriageDate)
          : "",
        divorceDate: initialData.person.divorceDate
          ? parseDate(initialData.person.divorceDate)
          : "",
        name: initialData.person.name ? initialData.person.name : "",
      },
    },
  });

  const civilStatusOptions = useSelector((state: RootState) => {
    return prepareEntitiesDropdownOptions(
      !isObjEmpty(entities.CivilStatus) ? entities.CivilStatus.values : {}
    );
  });

  const disabled =
    initialData.employee.processingState === ProcessingStates.Committed ||
    initialData.employee.processingState === ProcessingStates.Exported;

  const [initialFormToCompare, setInitialFormToCompare] = useState();
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const globalSettings = JSON.parse(localStorage.getItem("global-settings"));

  const checkCivilStatusSelected = (value, requiredFields) => {
    if (value == CivilStatusCodes.Married) {
      delete requiredFields["divorceDate"];
    } else if (value == CivilStatusCodes.Divorced) {
      delete requiredFields["marriageDate"];
    } else {
      delete requiredFields["marriageDate"];
      delete requiredFields["divorceDate"];
    }
    return requiredFields;
  };

  const getFetchFormCustomization = async () => {
    // remeber to put validation on this date
    const response = await fetchFormCustomization(
      globalSettings.attributes.tenantCode,
      "formCivilStatus"
    );
    if (response.data) {
      let formComponentsCustomization = {};
      response.data.attributes?.components.map(
        (item) =>
          (formComponentsCustomization[item.id] = item.componentAttributes)
      );

      let requiredFields = {};
      Object.keys(formComponentsCustomization).map(
        (key) =>
          formComponentsCustomization[key].required &&
          (requiredFields[key] = !!getValues("person")[key])
      );

      requiredFields = checkCivilStatusSelected(
        getValues("person.civilStatus"),
        requiredFields
      );

      isMarriageDateRequired =
        formComponentsCustomization["marriageDate"]?.required;
      isDivorceDateRequired =
        formComponentsCustomization["divorceDate"]?.required;

      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: formComponentsCustomization,
          messages: {},
        },
        requiredFields,
      });
    } else {
      isMarriageDateRequired = false;
      isDivorceDateRequired = false;
      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: {
            divorceDate: {
              required: false,
              date: {
                dayFilter: 0,
              },
            },
            marriageDate: {
              required: true,
              date: {
                dayFilter: 0,
              },
            },
            civilStatus: {
              required: true,
            },
            customizable2: {
              required: false,
            },
            customizable6: {
              required: false,
            },
            customizable7: {
              required: false,
            },
            personNote: {
              required: false,
            },
            customizable8: {
              required: false,
            },
            name: {
              required: false,
            },
          },
          messages: {},
        },
        requiredFields: {
          // divorceDate: !!getValues("person.divorceDate"),
          // marriageDate: !!getValues("person.marriageDate"),
          // civilStatus: !!getValues("person.civilStatus"),
          // customizable2: !!getValues("person.customizable2"),
          // customizable6: !!getValues("person.customizable6"),
          // customizable7: !!getValues("person.customizable7"),
          // personNote: !!getValues("person.personNote"),
          // customizable8: !!getValues("person.customizable8"),
          // name: !!getValues("person.name"),
        },
      });
    }
    // Object assign to remove reference
    const actualValues = Object.assign(
      {},
      {
        ...getValues("person"),
        customizable2: initialData.person.customizable2
          ? parseDate(initialData.person.customizable2)
          : "",
        marriageDate: initialData.person.marriageDate
          ? parseDate(initialData.person.marriageDate)
          : "",
        divorceDate: initialData.person.divorceDate
          ? parseDate(initialData.person.divorceDate)
          : "",
        name: initialData.person.name ? initialData.person.name : "",
      }
    );
    // Compare on edit
    setInitialFormToCompare(actualValues);
  };

  useEffect(() => {
    getFetchFormCustomization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeRequiredFieldFromFormCustomization = (
    field,
    value: boolean,
    formConfig
  ) => {
    return {
      ...formConfig,
      formCustomization: {
        ...formConfig.formCustomization,
        components: {
          ...formConfig.formCustomization.components,
          [field]: {
            ...formConfig.formCustomization.components[field],
            required: value,
          },
        },
      },
    };
  };

  const prepareCivilStatus = (value) => {
    let localFormCustomization = formConfig;

    // CivilStatusValidation
    if (
      value == CivilStatusCodes.Married ||
      value === CivilStatusCodes.RegisteredPartnership
    ) {
      localFormCustomization = changeRequiredFieldFromFormCustomization(
        "marriageDate",
        isMarriageDateRequired,
        localFormCustomization
      );
      localFormCustomization = changeRequiredFieldFromFormCustomization(
        "divorceDate",
        false,
        localFormCustomization
      );
      if (isMarriageDateRequired) {
        localFormCustomization.requiredFields = {
          ...localFormCustomization.requiredFields,
          marriageDate: false,
        };
      }
    } else if (value == CivilStatusCodes.Divorced) {
      localFormCustomization = changeRequiredFieldFromFormCustomization(
        "divorceDate",
        isDivorceDateRequired,
        localFormCustomization
      );
      localFormCustomization = changeRequiredFieldFromFormCustomization(
        "marriageDate",
        false,
        localFormCustomization
      );
      if (isDivorceDateRequired) {
        localFormCustomization.requiredFields = {
          ...localFormCustomization.requiredFields,
          divorceDate: false,
        };
      }
      // setFormConfig(localFormCustomization);
    } else {
      delete (localFormCustomization.requiredFields as any)?.marriageDate;
      delete (localFormCustomization.requiredFields as any)?.divorceDate;
      localFormCustomization = changeRequiredFieldFromFormCustomization(
        "marriageDate",
        false,
        localFormCustomization
      );
      localFormCustomization = changeRequiredFieldFromFormCustomization(
        "divorceDate",
        false,
        localFormCustomization
      );
      // setFormConfig(localFormCustomization);
    }
    // setValue("person.marriageDate", "");
    // setValue("person.divorceDate", "");

    setValue("person.civilStatus", !!value ? parseInt(value) : null);
    const validation: ValidationMessage = validateInputMessage(
      localFormCustomization.formCustomization,
      getValues("person"),
      "civilStatus",
      null,
      localFormCustomization.requiredFields
    );

    setError("person.civilStatus", validation);
    validation?.valid &&
      setFormConfig({
        ...localFormCustomization,
        requiredFields: validation?.valid,
      });
  };

  useEffect(() => {
    setIsDisabledButton(
      !Object.keys(formConfig.requiredFields).every(
        (key) => formConfig.requiredFields[key]
      )
    );
  }, [formConfig.requiredFields]);

  return (
    <>
      {isLoading ? (
        <Spinner relative />
      ) : (
        <form
          className="form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <fieldset disabled={disabled}>
            <div className="row">
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="person.civilStatus"
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      id="civilStatus"
                      name="civilStatus"
                      readOnly={disabled}
                      inputWrapperClass="dropdown-input"
                      placeholder=""
                      label="Anrede"
                      value={
                        (entities.CivilStatus &&
                          entities.CivilStatus.values[value]) ||
                        value ||
                        ""
                      }
                      selectOptions={civilStatusOptions}
                      onSelected={(name, valueInput) => {
                        prepareCivilStatus(valueInput);
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message: errors?.person?.civilStatus?.message,
                          type: errors?.person?.civilStatus
                            ?.type as ValidationMessageType,
                        },
                      }}
                      sort
                    >
                      <FormattedMessage
                        id="app.civilstatus"
                        defaultMessage="Zivilstand"
                      />
                      {formConfig.formCustomization.components["civilStatus"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              <div className="col-12 col-sm-6"></div>

              <div className="col-12 col-sm-6">
                {(+getValues("person.civilStatus") ===
                  CivilStatusCodes.Married ||
                  +getValues("person.civilStatus") ===
                    CivilStatusCodes.RegisteredPartnership) &&
                  !formConfig.formCustomization.components["marriageDate"]
                    ?.hidden && (
                    <Controller
                      control={control}
                      name="person.marriageDate"
                      render={({ field: { value } }) => (
                        <Input
                          type="date"
                          id="marriageDate"
                          name="marriageDate"
                          placeholder=""
                          label="label"
                          maxLength={10}
                          inputWrapperClass="text-input"
                          value={
                            value && value.length >= 10
                              ? parseDate(value)
                              : value
                          }
                          onChange={(name, valueInput) => {
                            setValue("person.marriageDate", valueInput);
                            const validation: ValidationMessage =
                              validateInputMessage(
                                formConfig.formCustomization,
                                getValues("person"),
                                "marriageDate",
                                () => [
                                  EValidationTestType.dateIsNotBeforeBirthdate,
                                ],
                                formConfig.requiredFields
                              );

                            setError("person.marriageDate", validation);
                            validation?.valid &&
                              setFormConfig({
                                ...formConfig,
                                requiredFields: validation?.valid,
                              });
                          }}
                          validationMessageConfig={{
                            visible: showValidations,
                            config: {
                              message: errors?.person?.marriageDate?.message,
                              type: errors?.person?.marriageDate
                                ?.type as ValidationMessageType,
                            },
                          }}
                        >
                          <FormattedMessage
                            id={
                              +getValues("person.civilStatus") ===
                              CivilStatusCodes.Married
                                ? "app.marriagedate"
                                : "app.partnerschaftdate"
                            }
                            defaultMessage={
                              +getValues("person.civilStatus") ===
                              CivilStatusCodes.Married
                                ? "Heiratsdatum"
                                : "Datum eingetragene Partnerschaft"
                            }
                          />
                          {formConfig.formCustomization.components[
                            "marriageDate"
                          ]?.required &&
                          +getValues("person.civilStatus") ==
                            CivilStatusCodes.Married
                            ? " *"
                            : ""}
                        </Input>
                      )}
                    />
                  )}
              </div>

              <div className="col-12 col-sm-6"></div>

              <div className="col-12 col-sm-6">
                {+getValues("person.civilStatus") ===
                  CivilStatusCodes.Married &&
                  !formConfig.formCustomization.components["marriageDate"]
                    ?.hidden && (
                    <Controller
                      control={control}
                      name="person.customizable6"
                      render={({ field: { value } }) => (
                        <Input
                          type="text"
                          id="customizable6"
                          name="customizable6"
                          placeholder=""
                          label="label"
                          inputWrapperClass="text-input"
                          value={value}
                          onChange={(name, valueInput) => {
                            setValue(
                              "person.customizable6",
                              filterOnlyLetters(valueInput)
                            );
                            const validation: ValidationMessage =
                              validateInputMessage(
                                formConfig.formCustomization,
                                getValues("person"),
                                "customizable6",
                                () => [
                                  EValidationTestType.dateIsNotBeforeBirthdate,
                                ],
                                formConfig.requiredFields
                              );

                            setError("person.customizable6", validation);
                            validation?.valid &&
                              setFormConfig({
                                ...formConfig,
                                requiredFields: validation?.valid,
                              });
                          }}
                          validationMessageConfig={{
                            visible: showValidations,
                            config: {
                              message: errors?.person?.customizable6?.message,
                              type: errors?.person?.customizable6
                                ?.type as ValidationMessageType,
                            },
                          }}
                        >
                          <FormattedMessage
                            id="app.nameofspouse"
                            defaultMessage="Name Ehepartner/in"
                          />
                          {formConfig.formCustomization.components[
                            "customizable6"
                          ]?.required
                            ? " *"
                            : ""}
                        </Input>
                      )}
                    />
                  )}
              </div>

              <div className="col-12 col-sm-6">
                {+getValues("person.civilStatus") ===
                  CivilStatusCodes.Married &&
                  !formConfig.formCustomization.components["marriageDate"]
                    ?.hidden && (
                    <Controller
                      control={control}
                      name="person.customizable7"
                      render={({ field: { value } }) => (
                        <Input
                          type="text"
                          id="customizable7"
                          name="customizable7"
                          placeholder=""
                          label="label"
                          inputWrapperClass="text-input"
                          value={value}
                          onChange={(name, valueInput) => {
                            setValue(
                              "person.customizable7",
                              filterOnlyLetters(valueInput)
                            );
                            const validation: ValidationMessage =
                              validateInputMessage(
                                formConfig.formCustomization,
                                getValues("person"),
                                "customizable7",
                                () => [
                                  EValidationTestType.dateIsNotBeforeBirthdate,
                                ],
                                formConfig.requiredFields
                              );

                            setError("person.customizable7", validation);
                            validation?.valid &&
                              setFormConfig({
                                ...formConfig,
                                requiredFields: validation?.valid,
                              });
                          }}
                          validationMessageConfig={{
                            visible: showValidations,
                            config: {
                              message: errors?.person?.customizable7?.message,
                              type: errors?.person?.customizable7
                                ?.type as ValidationMessageType,
                            },
                          }}
                        >
                          <FormattedMessage
                            id="app.firstnameofspouse"
                            defaultMessage="Vorname Ehepartner/in"
                          />
                          {formConfig.formCustomization.components[
                            "customizable7"
                          ]?.required
                            ? " *"
                            : ""}
                        </Input>
                      )}
                    />
                  )}
              </div>

              <div className="col-12 col-sm-6">
                {+getValues("person.civilStatus") ===
                  CivilStatusCodes.Married &&
                  !formConfig.formCustomization.components["marriageDate"]
                    ?.hidden && (
                    <Controller
                      control={control}
                      name="person.customizable2"
                      render={({ field: { value } }) => (
                        <Input
                          type="date"
                          id="customizable2"
                          name="customizable2"
                          placeholder=""
                          label="label"
                          inputWrapperClass="text-input"
                          maxLength={10}
                          value={
                            value && value.length >= 10
                              ? parseDate(value)
                              : value
                          }
                          onChange={(name, valueInput) => {
                            setValue("person.customizable2", valueInput);
                            const validation: ValidationMessage =
                              validateInputMessage(
                                formConfig.formCustomization,
                                getValues("person"),
                                "customizable2",
                                () => [EValidationTestType.dateIsUntilToday],
                                formConfig.requiredFields
                              );

                            setError("person.customizable2", validation);
                            validation?.valid &&
                              setFormConfig({
                                ...formConfig,
                                requiredFields: validation?.valid,
                              });
                          }}
                          validationMessageConfig={{
                            visible: showValidations,
                            config: {
                              message: errors?.person?.customizable2?.message,
                              type: errors?.person?.customizable2
                                ?.type as ValidationMessageType,
                            },
                          }}
                        >
                          <FormattedMessage
                            id="app.birthdateofspouse"
                            defaultMessage="Geburtsdatum Ehepartner/in"
                          />
                          {formConfig.formCustomization.components[
                            "customizable2"
                          ]?.required
                            ? " *"
                            : ""}
                        </Input>
                      )}
                    />
                  )}{" "}
              </div>

              <div className="col-12 col-sm-6"></div>

              <div className="col-12 col-sm-6">
                {+getValues("person.civilStatus") ===
                  CivilStatusCodes.Divorced &&
                  !formConfig.formCustomization.components["divorceDate"]
                    ?.hidden && (
                    <Controller
                      control={control}
                      name="person.divorceDate"
                      render={({ field: { value } }) => (
                        <Input
                          type="date"
                          id="divorceDate"
                          name="divorceDate"
                          placeholder=""
                          label="label"
                          inputWrapperClass="text-input"
                          maxLength={10}
                          value={
                            value && value.length >= 10
                              ? parseDate(value)
                              : value
                          }
                          onChange={(name, valueInput) => {
                            setValue("person.divorceDate", valueInput);
                            const validation: ValidationMessage =
                              validateInputMessage(
                                formConfig.formCustomization,
                                getValues("person"),
                                "divorceDate",
                                () => [
                                  EValidationTestType.dateIsNotBeforeBirthdate,
                                ],
                                formConfig.requiredFields
                              );

                            setError("person.divorceDate", validation);
                            validation?.valid &&
                              setFormConfig({
                                ...formConfig,
                                requiredFields: validation?.valid,
                              });
                          }}
                          validationMessageConfig={{
                            visible: showValidations,
                            config: {
                              message: errors?.person?.divorceDate?.message,
                              type: errors?.person?.divorceDate
                                ?.type as ValidationMessageType,
                            },
                          }}
                        >
                          <FormattedMessage
                            id="app.divorcedate"
                            defaultMessage="Scheidungsdatum"
                          />
                          {formConfig.formCustomization.components[
                            "divorceDate"
                          ]?.required &&
                          +getValues("person.civilStatus") ==
                            CivilStatusCodes.Divorced
                            ? " *"
                            : ""}
                        </Input>
                      )}
                    />
                  )}
              </div>

              <div className="col-12 col-sm-6"></div>

              <div className="col-12 col-sm-6">
                {(+getValues("person.civilStatus") ===
                  CivilStatusCodes.Divorced ||
                  +getValues("person.civilStatus") ===
                    CivilStatusCodes.Married) &&
                  !formConfig.formCustomization.components["divorceDate"]
                    ?.hidden && (
                    <Controller
                      control={control}
                      name="person.name"
                      render={({ field: { value } }) => (
                        <Input
                          type="content"
                          id="name"
                          name="name"
                          placeholder=""
                          label="label"
                          inputWrapperClass="text-input"
                          value={value}
                          onChange={(name, valueInput) => {
                            setValue(
                              "person.name",
                              filterOnlyLetters(valueInput)
                            );
                            const validation: ValidationMessage =
                              validateInputMessage(
                                formConfig.formCustomization,
                                getValues("person"),
                                "name",
                                () => [
                                  EValidationTestType.dateIsNotBeforeBirthdate,
                                ],
                                formConfig.requiredFields
                              );
                            setError("person.name", validation);
                            validation?.valid &&
                              setFormConfig({
                                ...formConfig,
                                requiredFields: validation?.valid,
                              });
                          }}
                          validationMessageConfig={{
                            visible: showValidations,
                            config: {
                              message: errors?.person?.name?.message,
                              type: errors?.person?.name
                                ?.type as ValidationMessageType,
                            },
                          }}
                        >
                          <FormattedMessage
                            id="app.nameaftercivilstate"
                            defaultMessage="Name nach Heirat / Scheidung"
                          />
                          {formConfig.formCustomization.components["name"]
                            ?.required
                            ? " *"
                            : ""}
                        </Input>
                      )}
                    />
                  )}
              </div>

              <div className="col-12">
                <Controller
                  control={control}
                  name="person.personNote"
                  render={({ field: { value } }) => (
                    <Input
                      type="content"
                      hidden={
                        formConfig.formCustomization.components["personNote"]
                          ?.hidden
                      }
                      id="personNote"
                      name="personNote"
                      placeholder=""
                      label="label"
                      inputWrapperClass="text-input"
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue("person.personNote", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("person"),
                            "personNote",
                            null,
                            formConfig.requiredFields
                          );
                        setError("person.personNote", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message: errors?.person?.personNote?.message,
                          type: errors?.person?.personNote
                            ?.type as ValidationMessageType,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.remarks"
                        defaultMessage="Bemerkungen"
                      />
                      {formConfig.formCustomization.components["personNote"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              <div className="col-12 col-sm-6"></div>

              <div className="col-12 col-md-6">
                <div className="modal-buttons">
                  <Button
                    onClick={() => history.goBack()}
                    type={ButtonTypes.Text}
                    color={ButtonColors.Action}
                  >
                    <FormattedMessage
                      id="app.cancel"
                      defaultMessage="Abbrechen"
                    />
                  </Button>
                  <Button
                    className="modal-main-button primary width-1"
                    submit={true}
                    isLoading={isLoading}
                    type={ButtonTypes.Standard}
                    color={ButtonColors.Action}
                    style={ButtonStyles.Primary}
                    isDisabled={
                      isLoading ||
                      isDisabledButton ||
                      !formHasChanged(initialFormToCompare, getValues("person"))
                    }
                    onClick={(e) => {
                      submitAction(e, getValues());
                    }}
                  >
                    <FormattedMessage
                      id="app.submit"
                      defaultMessage="Bestätigen"
                    />
                  </Button>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      )}
    </>
  );
};

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    entities: state.app.entities,
    isLoading: state.app.loading,
    insurantsObj: state.insurants.insurantsObj,
    employers: state.app.employers,
    showValidations: state.app.showValidations,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(FormCivilStatus));
