import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../reducers/index";
import { Dispatch } from "redux";
import * as actions from "../../../actions";
import { dateFilter, parseDate } from "../../../utils/react";
import { ProcessingStates } from "../../../models";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
  Input,
  ValidationMessageType,
} from "icr-shared-components";
import "./FormRetirement.scss";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  ValidationFormProps,
  ValidationMessage,
} from "../../../@types/forms/agp/formValidation";
import { Controller, useForm } from "react-hook-form";
import {
  buildValidationBasedOnEmployerDates,
  formHasChanged,
  validateInputMessage,
} from "../../../utils/validationTools";
import { EValidationTestType } from "../../../utils/validationConfigs";
import { fetchFormCustomization } from "../../../utils/form";
import Spinner from "../../Spinner";

interface Props {
  checkFormValidity: any;
  civilStatusOptions?: any;
  closeModal: any;
  entities?: any;
  initialData: any;
  isLoading: boolean;
  submitAction: any;
  intl: any;
  employers: any;
  insurantsObj: any;
  history: any;
  location: any;
  backendValidation: any;
  removeBackendValidationMessage: any;
  showValidations: boolean;
}

const FormRetirement = ({
  initialData,
  submitAction,
  intl,
  showValidations,
  isLoading,
  history,
  employers,
}: Props) => {
  const [formConfig, setFormConfig] = useState<ValidationFormProps>({
    formCustomization: {
      components: {},
      messages: {},
    },
    requiredFields: {},
  });
  const {
    setValue,
    getValues,
    setError,
    control,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      employee: {
        ...initialData.employee,
        birthDate: initialData.person.birthDate,
        retirementDate: initialData.employee.retirementDate
          ? parseDate(initialData.employee.retirementDate)
          : "",
        degreeRetirement: initialData.employee.degreeRetirement
          ? initialData.employee.degreeRetirement
          : 0,
      },
    },
  });

  const disabled =
    initialData.employee.processingState === ProcessingStates.Committed ||
    initialData.employee.processingState === ProcessingStates.Exported;
  const [initialFormToCompare, setInitialFormToCompare] = useState();
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [partialRetirement, setPartialRetirement] = useState(false);
  const globalSettings = JSON.parse(localStorage.getItem("global-settings"));
  const getFetchFormCustomization = async () => {
    const response = await fetchFormCustomization(
      globalSettings.attributes.tenantCode,
      "formRetirement"
    );
    if (response.data) {
      const employerConfiguration = employers[initialData.employee.employerId];

      let formComponentsCustomization = {};
      response.data.attributes?.components.map(
        (item) =>
          (formComponentsCustomization[item.id] = item.componentAttributes)
      );

      let requiredFields = {};
      Object.keys(formComponentsCustomization).map(
        (key) =>
          formComponentsCustomization[key].required &&
          (requiredFields[key] = !!getValues("employee")[key])
      );

      // Build custom frontend validation
      formComponentsCustomization = buildValidationBasedOnEmployerDates(
        employerConfiguration,
        formComponentsCustomization,
        "retirementDate"
      );

      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: formComponentsCustomization,
          messages: {},
        },
        requiredFields,
      });
    } else {
      setFormConfig({
        ...formConfig,
        formCustomization: {
          components: {
            retirementDate: {
              required: true,
              date: {
                dayFilter: 0,
              },
              interval: {
                fieldToCompare: "birthDate"
              }
            },
            degreeRetirement: {
              required: false,
            },
            retirementContactPhoneNumber: {
              required: false,
            },
            retirementContactMobilePhoneNumber: {
              required: false,
            },
            retirementContactEmail: {
              required: false,
            },
          },
          messages: {},
        },
        requiredFields: {
          retirementDate: !!getValues("employee.retirementDate"),
          // degreeRetirement: !!getValues("employee.degreeRetirement"),
          // retirementContactPhoneNumber: !!getValues(
          //   "employee.retirementContactPhoneNumber"
          // ),
          // retirementContactMobilePhoneNumber: !!getValues(
          //   "employee.retirementContactMobilePhoneNumber"
          // ),
          // retirementContactEmail: !!getValues(
          //   "employee.retirementContactEmail"
          // ),
        },
      });
    }
    // Object assign to remove reference
    const actualValues = Object.assign(
      {},
      {
        ...getValues("employee"),
        retirementDate: initialData.employee.retirementDate
          ? parseDate(initialData.employee.retirementDate)
          : "",
        degreeRetirement: initialData.employee.degreeRetirement
          ? initialData.employee.degreeRetirement
          : 0,
      }
    );
    // Compare on edit
    setInitialFormToCompare(actualValues);
  };

  useEffect(() => {
    getFetchFormCustomization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsDisabledButton(
      !Object.keys(formConfig.requiredFields).every(
        (key) => formConfig.requiredFields[key]
      )
    );
  }, [formConfig.requiredFields]);

  useEffect(() => {
    if (initialData.employee.degreeRetirement) {
      setPartialRetirement(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const retirementDateValidation = () => {
    let validation = [];

    validation.push(dateFilter(
      formConfig.formCustomization.components["retirementDate"]?.date?.dayFilter
    ));
    validation.push(EValidationTestType.dateBetween)
    return validation.length > 0 ? validation : null;
  }

  return (
    <>
      {isLoading ? (
        <Spinner relative />
      ) : (
        <form
          className="form"
          onSubmit={(e) => {
            console.log("onsubmit", e);
            e.preventDefault();
          }}
        >
          <fieldset disabled={disabled}>
            <div className="row">
              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="employee.retirementDate"
                  render={({ field: { value } }) => (
                    <Input
                      isFocused
                      type="date"
                      hidden={
                        formConfig.formCustomization.components[
                          "retirementDate"
                        ]?.hidden
                      }
                      maxLength={10}
                      inputWrapperClass="text-input"
                      id="retirementDate"
                      name="retirementDate"
                      placeholder={intl.formatMessage({
                        id: "app.retirementdate",
                        defaultMessage: "Pensionierungdatum",
                      })}
                      value={value && value.length >= 10 ? parseDate(value) : value}
                      onChange={(name, valueInput) => {
                        setValue("employee.retirementDate", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("employee"),
                            "retirementDate",
                            retirementDateValidation,
                            formConfig.requiredFields
                          );
                        setError("employee.retirementDate", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("employee"),
                            "retirementDate",
                            retirementDateValidation,
                            formConfig.requiredFields
                          );
                        setError("employee.retirementDate", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message: errors?.employee?.retirementDate?.message,
                          type: errors?.employee?.retirementDate
                            ?.type as ValidationMessageType,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.retirementdate"
                        defaultMessage="Pensionierungdatum"
                      />
                      {formConfig.formCustomization.components["retirementDate"]
                        ?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              <div className="col-12 col-lg-6">
                <div className="row">
                  <div className="col-12 col-sm-6 col-lg-5 d-flex align-items-center">
                    <Input
                      type="checkbox"
                      inputWrapperClass="checkbox-input toggle-checkbox"
                      name="filterOnlyNewInbox"
                      hidden={
                        formConfig.formCustomization.components[
                          "degreeRetirement"
                        ]?.hidden
                      }
                      checked={partialRetirement}
                      value={partialRetirement}
                      checkboxLabel={"Teilpensionierung"}
                      onChange={(name, value) => {
                        setPartialRetirement(value);
                        setValue("employee.degreeRetirement", 0);
                      }}
                    ></Input>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-7">
                    <Controller
                      control={control}
                      name="employee.degreeRetirement"
                      render={({ field: { value } }) => (
                        <Input
                          type="number"
                          label="label"
                          hidden={
                            formConfig.formCustomization.components[
                              "degreeRetirement"
                            ]?.hidden || !partialRetirement
                          }
                          inputWrapperClass="text-input filter-input"
                          id="degreeRetirement"
                          name="degreeRetirement"
                          placeholder="Pensionierungsgrad"
                          value={value}
                          onChange={(name, valueInput) => {
                            setValue(
                              "employee.degreeRetirement",
                              parseInt(valueInput)
                            );
                            const validation: ValidationMessage =
                              validateInputMessage(
                                formConfig.formCustomization,
                                getValues("employee"),
                                "degreeRetirement",
                                () => [EValidationTestType.isNumber],
                                formConfig.requiredFields
                              );
                            setError("employee.degreeRetirement", validation);
                            validation?.valid &&
                              setFormConfig({
                                ...formConfig,
                                requiredFields: validation?.valid,
                              });
                          }}
                          onBlur={() => {
                            const validation: ValidationMessage =
                              validateInputMessage(
                                formConfig.formCustomization,
                                getValues("employee"),
                                "degreeRetirement",
                                () => [EValidationTestType.isNumber],
                                formConfig.requiredFields
                              );
                            setError("employee.degreeRetirement", validation);
                            validation?.valid &&
                              setFormConfig({
                                ...formConfig,
                                requiredFields: validation?.valid,
                              });
                          }}
                          validationMessageConfig={{
                            visible: showValidations,
                            config: {
                              message:
                                errors?.employee?.degreeRetirement?.message,
                              type: errors?.employee?.degreeRetirement
                                ?.type as ValidationMessageType,
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="employee.retirementContactPhoneNumber"
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      inputWrapperClass="text-input"
                      hidden={
                        formConfig.formCustomization.components[
                          "retirementContactPhoneNumber"
                        ]?.hidden
                      }
                      id="retirementContactPhoneNumber"
                      name="retirementContactPhoneNumber"
                      placeholder=""
                      label="label"
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue(
                          "employee.retirementContactPhoneNumber",
                          valueInput
                        );
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("employee"),
                            "retirementContactPhoneNumber",
                            () => [EValidationTestType.isNumber,EValidationTestType.isPhoneNumber],
                            formConfig.requiredFields
                          );
                        setError(
                          "employee.retirementContactPhoneNumber",
                          validation
                        );
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("employee"),
                            "retirementContactPhoneNumber",
                            () => [EValidationTestType.isNumber,EValidationTestType.isPhoneNumber],
                            formConfig.requiredFields
                          );
                        setError(
                          "employee.retirementContactPhoneNumber",
                          validation
                        );
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message:
                            errors?.employee?.retirementContactPhoneNumber
                              ?.message,
                          type: errors?.employee?.retirementContactPhoneNumber
                            ?.type as ValidationMessageType,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.phonenumber"
                        defaultMessage="Telefonnummer"
                      />
                      {formConfig.formCustomization.components[
                        "retirementContactPhoneNumber"
                      ]?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="employee.retirementContactMobilePhoneNumber"
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      inputWrapperClass="text-input"
                      hidden={
                        formConfig.formCustomization.components[
                          "retirementContactMobilePhoneNumber"
                        ]?.hidden
                      }
                      id="retirementContactMobilePhoneNumber"
                      name="retirementContactMobilePhoneNumber"
                      label="label"
                      placeholder=""
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue(
                          "employee.retirementContactMobilePhoneNumber",
                          valueInput
                        );
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("employee"),
                            "retirementContactMobilePhoneNumber",
                            () => [EValidationTestType.isNumber,EValidationTestType.isPhoneNumber],
                            formConfig.requiredFields
                          );
                        setError(
                          "employee.retirementContactMobilePhoneNumber",
                          validation
                        );
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("employee"),
                            "retirementContactMobilePhoneNumber",
                            () => [EValidationTestType.isNumber,EValidationTestType.isPhoneNumber],
                            formConfig.requiredFields
                          );
                        setError(
                          "employee.retirementContactMobilePhoneNumber",
                          validation
                        );
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message:
                            errors?.employee?.retirementContactMobilePhoneNumber
                              ?.message,
                          type: errors?.employee
                            ?.retirementContactMobilePhoneNumber
                            ?.type as ValidationMessageType,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.mobilenumber"
                        defaultMessage="Mobilenummer"
                      />
                      {formConfig.formCustomization.components[
                        "retirementContactMobilePhoneNumber"
                      ]?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              <div className="col-12 col-sm-6">
                <Controller
                  control={control}
                  name="employee.retirementContactEmail"
                  render={({ field: { value } }) => (
                    <Input
                      type="text"
                      label="label"
                      inputWrapperClass="text-input"
                      hidden={
                        formConfig.formCustomization.components[
                          "retirementContactEmail"
                        ]?.hidden
                      }
                      id="retirementContactEmail"
                      name="retirementContactEmail"
                      placeholder=""
                      value={value}
                      onChange={(name, valueInput) => {
                        setValue("employee.retirementContactEmail", valueInput);
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("employee"),
                            "retirementContactEmail",
                            () => [EValidationTestType.isEmail],
                            formConfig.requiredFields
                          );
                        setError("employee.retirementContactEmail", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      onBlur={() => {
                        const validation: ValidationMessage =
                          validateInputMessage(
                            formConfig.formCustomization,
                            getValues("employee"),
                            "retirementContactEmail",
                            () => [EValidationTestType.isEmail],
                            formConfig.requiredFields
                          );
                        setError("employee.retirementContactEmail", validation);
                        validation?.valid &&
                          setFormConfig({
                            ...formConfig,
                            requiredFields: validation?.valid,
                          });
                      }}
                      validationMessageConfig={{
                        visible: showValidations,
                        config: {
                          message:
                            errors?.employee?.retirementContactEmail?.message,
                          type: errors?.employee?.retirementContactEmail
                            ?.type as ValidationMessageType,
                        },
                      }}
                    >
                      <FormattedMessage
                        id="app.email"
                        defaultMessage="Email Adresse"
                      />
                      {formConfig.formCustomization.components[
                        "retirementContactEmail"
                      ]?.required
                        ? " *"
                        : ""}
                    </Input>
                  )}
                />
              </div>

              <div className="col-12 col-sm-6" />
              <div className="col-12 col-sm-6" />
              <div className="col-12 col-sm-6">
                <div className="modal-buttons">
                  <Button
                    onClick={() => history.goBack()}
                    type={ButtonTypes.Text}
                    color={ButtonColors.Action}
                  >
                    <FormattedMessage
                      id="app.cancel"
                      defaultMessage="Abbrechen"
                    />
                  </Button>
                  <Button
                    className="modal-main-button primary width-1"
                    submit={true}
                    isLoading={isLoading}
                    type={ButtonTypes.Standard}
                    color={ButtonColors.Action}
                    style={ButtonStyles.Primary}
                    isDisabled={
                      isLoading ||
                      isDisabledButton ||
                      !formHasChanged(
                        initialFormToCompare,
                        getValues("employee")
                      )
                    }
                    onClick={(e) => {
                      delete getValues("employee").birthDate;
                      submitAction(e, getValues());
                    }}
                  >
                    <FormattedMessage
                      id="app.submit"
                      defaultMessage="Bestätigen"
                    />
                  </Button>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      )}
    </>
  );
};

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    entities: state.app.entities,
    isLoading: state.app.loading,
    employers: state.app.employers,
    insurantsObj: state.insurants.insurantsObj,
    showValidations: state.app.showValidations,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(FormRetirement));
