import React from "react";
import { parseDate } from "../../../../utils/react";
import {
  Button,
  ButtonColors,
  ButtonSizes,
  ButtonTypes,
} from "icr-shared-components";
import { ReactComponent as IconEdit } from "../../../../assets/icons/edit.svg";
import { ReactComponent as IconPdfDownload } from "../../../../assets/icons/pdfdownload.svg";
import styles from "./MutationExpadableRowTop.module.scss";
import { createMutationContent } from "../../../../utils/mutation";
import classNames from "classnames";
import { RootState } from "../../../../reducers";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { ProcessingStates } from "../../../../models";

interface Props {
  intl: any;
  loading: boolean;
  mutation: any;
  onEditClick: any;
  onDownloadClick: any;
  expanded: boolean;
  onDeleteClick: () => void;
  toggleExpand: () => void;
}

const MutationExpandableRowTop = ({
  mutation,
  onEditClick,
  onDownloadClick,
  onDeleteClick,
  toggleExpand,
  expanded,
  loading,
}: Props) => {
  const isEditable = !!(
    mutation.attributes.mutationType.name !== "Adresslöschung" &&
    mutation.attributes.mutationType.name !== "Lohnlöschung" &&
    mutation.attributes.mutationType.name !== "UnbezahlterUrlaublöschung" &&
    mutation.attributes.mutationType.name !== "Invaliditaet"
  );

  const hasMoreContent = !!createMutationContent(mutation).more.length;

  return (
    <div className={styles.mutationExpandableRowTop}>
      <div className={styles.column}>
        <span className={styles.mobileHeader}>Typ / Name</span>
        <p className="text-small">
          {mutation.attributes.isRevert && (
            <span className="mr-6">
              <FormattedMessage
                id="mutation.Revert.TypePrefix"
                defaultMessage="Storno"
              />
            </span>
          )}
          {mutation.attributes.mutationType?.translation}
        </p>
        <p>
          <b>{`${mutation.attributes.name} ${mutation.attributes.firstname}`}</b>
        </p>
      </div>

      <div className={styles.column}>
        <span className={styles.mobileHeader}>Gültig ab</span>
        <p>{parseDate(mutation.attributes.keyDate)}</p>
      </div>

      <div className={classNames(styles.column)}>
        {createMutationContent(mutation).content.map(
          ({ prevValue, value, title, translation }) => (
            <>
              <div className={styles.mutationContentRow}>
                <div className={styles.mutationContentRowLeft}>
                  <p className="text-small">
                    <FormattedMessage id={translation} defaultMessage={title} />
                  </p>
                  <span className={styles.mobileHeader}>
                    <FormattedMessage id={translation} defaultMessage={title} />{" "}
                    alt
                  </span>
                  <p>
                    <b>{prevValue}</b>
                  </p>
                </div>
                <div className={styles.mutationContentRowRight}>
                  <span className={styles.mobileHeader}>
                    <FormattedMessage id={translation} defaultMessage={title} />{" "}
                    neu
                  </span>
                  <p>
                    <b>
                      {mutation.attributes.isRevert ? (
                        <FormattedMessage
                          id="mutation.Revert.Text"
                          defaultMessage="Storniert"
                        />
                      ) : (
                        value
                      )}
                    </b>
                  </p>
                </div>
              </div>
              <div
                className={classNames(styles.expandable, {
                  [styles.expanded]: expanded,
                })}
              >
                {createMutationContent(mutation).more.map(
                  ({ prevValue, value, title, translation }) => (
                    <div
                      className={classNames(
                        styles.mutationContentRow,
                        styles.more
                      )}
                    >
                      <div className={styles.mutationContentRowLeft}>
                        <p className="text-small">
                          <FormattedMessage
                            id={translation}
                            defaultMessage={title}
                          />
                        </p>
                        <p>{prevValue}</p>
                      </div>
                      <div className={styles.mutationContentRowRight}>
                        <p>
                          <b>
                            {mutation.attributes.isRevert ? (
                              <FormattedMessage
                                id="mutation.Revert.Text"
                                defaultMessage="Storniert"
                              />
                            ) : (
                              value
                            )}
                          </b>
                        </p>
                      </div>
                    </div>
                  )
                )}
              </div>

              {hasMoreContent && (
                <Button
                  className="mt-16"
                  type={ButtonTypes.Text}
                  color={ButtonColors.Action}
                  size={ButtonSizes.Small}
                  onClick={toggleExpand}
                >
                  {expanded ? "Weniger" : "Mehr"}
                </Button>
              )}
            </>
          )
        )}
      </div>

      <div className={styles.column}>
        <span className={styles.mobileHeader}>Quelle / Datum / Bearbeiter</span>
        {/*<p className="text-small">CSV-Import</p>*/}
        <p>{mutation.attributes.username}</p>
        <p className="text-small">{parseDate(mutation.attributes.timeStamp)}</p>
      </div>

      <div className={styles.column}>
        <Button
          className={classNames("edit-icon mr-24", { invisible: !isEditable })}
          type={ButtonTypes.Text}
          isDisabled={
            mutation.attributes.processingState != 1 ||
            mutation.attributes.isMadeByImport ||
            mutation.attributes.hasError
          }
          onClick={onEditClick}
        >
          <IconEdit />
        </Button>

        <Button
          className="download-icon"
          type={ButtonTypes.Text}
          isDisabled={mutation.attributes.hasError && mutation.attributes.isMadeByImport}
          onClick={() => onDownloadClick()}
        >
          <IconPdfDownload />
        </Button>
      </div>

      <div className={styles.row}>
        <Button
          type={ButtonTypes.Text}
          isDisabled={
            mutation.attributes.processingState != ProcessingStates.Changed ||
            loading ||
            mutation.attributes.isMadeByImport ||
            mutation.attributes.mutationType.name == "Invaliditaet"
          }
          onClick={() => onDeleteClick()}
        >
          Löschen
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState, ownProps: any) => ({
  loading: state.app.loading,
});
const connector = connect(mapStateToProps, null);
export default connector(injectIntl(MutationExpandableRowTop));
